exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cookie-tsx": () => import("./../../../src/pages/cookie.tsx" /* webpackChunkName: "component---src-pages-cookie-tsx" */),
  "component---src-pages-data-protection-tsx": () => import("./../../../src/pages/data-protection.tsx" /* webpackChunkName: "component---src-pages-data-protection-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-subscribe-for-major-updates-tsx": () => import("./../../../src/pages/subscribe-for-major-updates.tsx" /* webpackChunkName: "component---src-pages-subscribe-for-major-updates-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-use-cases-entrepreneurs-tsx": () => import("./../../../src/pages/use-cases/entrepreneurs.tsx" /* webpackChunkName: "component---src-pages-use-cases-entrepreneurs-tsx" */),
  "component---src-pages-use-cases-freelancers-and-consultants-tsx": () => import("./../../../src/pages/use-cases/freelancers-and-consultants.tsx" /* webpackChunkName: "component---src-pages-use-cases-freelancers-and-consultants-tsx" */),
  "component---src-pages-use-cases-managers-tsx": () => import("./../../../src/pages/use-cases/managers.tsx" /* webpackChunkName: "component---src-pages-use-cases-managers-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/article-page.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */)
}

